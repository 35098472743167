// extracted by mini-css-extract-plugin
export var ScheduleSection = "acquirers-module--ScheduleSection--e4833";
export var add_margin = "acquirers-module--add_margin--7b20e";
export var awardsSection = "acquirers-module--awardsSection--cd8b1";
export var awardsSection__awards = "acquirers-module--awardsSection__awards--c1887";
export var awardsSection__title = "acquirers-module--awardsSection__title--09345";
export var heroSection__backgroundImg = "acquirers-module--heroSection__backgroundImg--c82a6";
export var heroSection__button__wrapper = "acquirers-module--heroSection__button__wrapper--7ede7";
export var heroSection__content = "acquirers-module--heroSection__content--f2b19";
export var heroSection__hint = "acquirers-module--heroSection__hint--e3ea7";
export var heroSection__image = "acquirers-module--heroSection__image--a2e77";
export var heroSection__subtitle = "acquirers-module--heroSection__subtitle--97d80";
export var heroSection__textContainer = "acquirers-module--heroSection__textContainer--f7cf8";
export var heroSection__title = "acquirers-module--heroSection__title--b2ad3";
export var heroSection__wrapper = "acquirers-module--heroSection__wrapper--03476";
export var horizontal__Img = "acquirers-module--horizontal__Img--b02dd";
export var mouseContainer = "acquirers-module--mouseContainer--c2080";
export var partnersSection = "acquirers-module--partnersSection--07e0d";
export var partnersSection__description = "acquirers-module--partnersSection__description--798bb";
export var partnersSection__img = "acquirers-module--partnersSection__img--7fdba";
export var partnersSection__partners = "acquirers-module--partnersSection__partners--d8a49";
export var partnersSection__title = "acquirers-module--partnersSection__title--0d801";
export var productsSection = "acquirers-module--productsSection--6c1b7";
export var productsSection__description = "acquirers-module--productsSection__description--83f62";
export var productsSection__product = "acquirers-module--productsSection__product--3734f";
export var productsSection__product__brands = "acquirers-module--productsSection__product__brands--cd591";
export var productsSection__product__button = "acquirers-module--productsSection__product__button--db87f";
export var productsSection__product__description = "acquirers-module--productsSection__product__description--a1a3b";
export var productsSection__product__image = "acquirers-module--productsSection__product__image--ecf42";
export var productsSection__product__subtitle = "acquirers-module--productsSection__product__subtitle--ad4c2";
export var productsSection__product__title = "acquirers-module--productsSection__product__title--04e3b";
export var productsSection__products = "acquirers-module--productsSection__products--89d28";
export var productsSection__title = "acquirers-module--productsSection__title--ea143";
export var vertical__Img = "acquirers-module--vertical__Img--9fb8e";
export var zealsandboxSection = "acquirers-module--zealsandboxSection--551c7";
export var zealsandboxSection__content = "acquirers-module--zealsandboxSection__content--b35bb";
export var zealsandboxSection__subtitle = "acquirers-module--zealsandboxSection__subtitle--34b9d";
export var zealsandboxSection__title = "acquirers-module--zealsandboxSection__title--7f018";