import React from "react";
import HeroGradient from "../components/HeroGradient";
import { motion } from "framer-motion";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import MouseScrollAnimated from "../components/MouseScrollAnimated";
import * as styles from "../styles/pages/acquirers.module.css";
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../utils/animation-variants";
import { MotionLink } from "../components/Header";
import move5000pos from "../images/acquirers/move5000pos.png";
import terminalSoultion from "../images/acquirers/terminal-solution.png";
import cardsStack from "../images/acquirers/cards-stack.png";
import Layout from "../components/Layout";
import YellowSection from "../components/YellowSection";
import Seo from "../components/SEO";
import ScheduleSection from "../components/ScheduleSection";

import BarclaysAward from "../images/awards/barclays.png";
import SetSquared from "../images/awards/setsquared.png";
import Paytech from "../images/awards/paytech.png";
import Enterprise from "../images/awards/enterprise.png";
import VisaAward from "../images/awards/visa.png";
import Worldbank from "../images/awards/worldbank.png";

import laptop from "../images/acquirers/laptop.png";

//partners images
import network from '../images/acquirers/partners/network.png';
import oracleMicros from '../images/acquirers/partners/oracle-micros.png';
import ingenico from '../images/acquirers/partners/ingenico.png';
import verifone from '../images/acquirers/partners/verifone.png';
import pax from '../images/acquirers/partners/pax.png';
import urovo from '../images/acquirers/partners/urovo.png';
import sunmi from '../images/acquirers/partners/sunmi.png';
import aisino from '../images/acquirers/partners/aisino.png';
import { graphql } from "gatsby";
import clsx from "clsx";

const ProductsSection = () => {
  const { t } = useTranslation();
  const ProductsSectionData = [
    {
      title: t("identify.customers.title"),
      description: t("identify.customers.desc"),
      image: move5000pos,
      aspectRatio: 2 / 1,
      link: "/acquirers/#schedule",
      className: "max-lg:mb-[-50px]",
    },
    {
      title: t("card.bin.title"),
      description: t("card.bin.desc"),
      image: cardsStack,
      aspectRatio: 2 / 1,
      link: "/acquirers/#schedule",
    },
    {
      title: t("terminal.agnostic.title"),
      description: t("terminal.agnostic.desc"),
      image: terminalSoultion,
      aspectRatio: 2 / 1,
      link: "/acquirers/#schedule",
    },
  ];
  return (
    <section className={`${styles.productsSection} sectionContainer`}>
      <motion.h2
        {...revealContainerProps}
        variants={revealVariant}
        className={styles.productsSection__title}
      >
        {t("acquirers.productsSection.title")}
      </motion.h2>

      <motion.div
        {...revealContainerProps}
        className={styles.productsSection__products}
      >
        {ProductsSectionData.map((product) => (
          <motion.div
            {...revealContainerWithChildrenProps}
            variants={revealVariant}
            className={clsx([
              styles.productsSection__product,
              product.aspectRatio > 1
                ? styles.vertical__Img
                : styles.horizontal__Img,
            ])}
            key={product.title}
          >
            <motion.div
              variants={revealVariant}
              className={clsx([
                styles.productsSection__product__image,
                product.aspectRatio > 1 && "elevetion-animation",
                "max-w-[360px]",
                product.className,
              ])}
            >
              <motion.img
                variants={revealVariant}
                src={product.image}
                alt={product.title}
              />
            </motion.div>
            <motion.div {...revealContainerWithChildrenProps}>
              <motion.h3
                variants={revealVariant}
                className={styles.productsSection__product__title}
              >
                {product.title}
              </motion.h3>

              <motion.p
                variants={revealVariant}
                className={styles.productsSection__product__description}
              >
                {product.description}
              </motion.p>

              <MotionLink
                variants={revealVariant}
                to={product.link}
                className={`button button--gradient ${styles.productsSection__product__button}`}
              >
                Try Now
              </MotionLink>
            </motion.div>
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
};

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <section>
      <div className={`${styles.heroSection__wrapper} `}>
        <HeroGradient />
        <motion.div
          {...revealContainerWithChildrenProps}
          className={clsx([
            // styles.heroSection__content,
            "relative flex flex-col gap-8 items-center text-center px-8 justify-center",
            "lg:flex-row lg:max-xl:items-end lg:justify-center lg:gap-12 ",
          ])}
        >
          <motion.div
            variants={revealVariant}
            // className={`${styles.heroSection__textContainer} max-w-[600px]`}
            className={clsx([
              styles.heroSection__textContainer,
              "lg:min-w-[460px] ",
              "max-w-[600px] lg:max-xl:pb-[50px] lg:pt-[105px]",
            ])}
          >
            <motion.h1
              variants={revealVariant}
              className={styles.heroSection__title}
              dangerouslySetInnerHTML={{
                __html: t("acquirers.heroSection.title", {
                  highlight: `<span>${t(
                    "acquirers.heroSection.title.highlight"
                  )}</span>`,
                }),
              }}
            />
            <motion.p
              variants={revealVariant}
              className={styles.heroSection__subtitle}
            >
              {t("acquirers.heroSection.subtitle")}
            </motion.p>
            <motion.p
              className={styles.heroSection__hint}
              variants={revealVariant}
            >
              {t("acquirers.heroSection.subtitle2")}
            </motion.p>

            <div className={styles.heroSection__button__wrapper}>
              <MotionLink
                to="/sandbox/signup"
                className={`button button--primary ${styles.heroSection__button}`}
              >
                {t("acquirers.heroSection.button")}
              </MotionLink>
            </div>
          </motion.div>
          <motion.div variants={revealVariant} className="">
            <StaticImage
              alt="Zeal Pay"
              src={"../images/acquirers/pos-devices.png"}
              className={" w-full max-w-[631px]"}
            />
          </motion.div>
          <div className="absolute bottom-8">
            <MouseScrollAnimated />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

const Partners = () => {
  const { t } = useTranslation();
  const partners = [
    {
      title: "Network",
      image: network,
    },
    {
      title: "Oracle Micros",
      image: oracleMicros,
    },
    {
      title: "Ingenico",
      image: ingenico,
    },
    {
      title: "Verifone",
      image: verifone,
    },
    {
      title: "UROVO",
      image: urovo,
    },
    {
      title: "Sunmi",
      image: sunmi,
    },
    {
      title: "Aisino",
      image: aisino,
    },
    {
      title: "PAX",
      image: pax,
    },
  ];

  return (
		<motion.section
			{...revealContainerProps}
			className={styles.partnersSection}
		>
			<motion.h2
				variants={revealVariant}
				className={styles.partnersSection__title}
			>
				{t('acquirers.partnersSection.title')}
			</motion.h2>
			<motion.p
				variants={revealVariant}
				className={styles.partnersSection__description}
			>
				{t('acquirers.partnersSection.subtitle')}
			</motion.p>
			<motion.div
				className={clsx([
					'grid gap-x-9 gap-y-6 grid-cols-2 justify-center',
					'md:grid-cols-[repeat(4,minmax(0,130px))]',
					'min-[1440px]:flex min-[1440px]:flex-row min-[1440px]:gap-8 min-[1440px]:justify-center',
				])}
				{...revealContainerWithChildrenProps}
			>
				{partners.map((partner) => (
					<motion.div
						key={partner.title}
						variants={revealVariant}
						className="flex justify-center items-center min-w-[130px] min-h-[65px]"
					>
						<GatsbyImage
							className={`${styles.partnersSection__img}`}
							key={partner.title}
							src={partner.image}
						/>
					</motion.div>
				))}
			</motion.div>
		</motion.section>
	);
};

const ZealSandbox = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.zealsandboxSection}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.zealsandboxSection__content}`}
      >
        <motion.h2
          variants={revealVariant}
          className={styles.zealsandboxSection__title}
        >
          {t("acquirers.zealsandboxSection.title")}
        </motion.h2>
        <motion.p
          variants={revealVariant}
          className={styles.zealsandboxSection__subtitle}
        >
          {t("acquirers.zealsandboxSection.subtitle")}
        </motion.p>
        <MotionLink
          variants={revealVariant}
          to="/sandbox/signup"
          className={`${styles.heroSection__button} button button--gradient lg:mb-[44px] mb-[40px]`}
        >
          {t("acquirers.zealsandboxSection.button")}
        </MotionLink>

        <motion.img variants={revealVariant} src={laptop} alt="zeal demo" />
      </motion.div>
    </section>
  );
};

const awards = [
  {
    id: "barclays",
    title: "Barclays Award",
    image: BarclaysAward,
  },
  {
    id: "enterprise",
    title: "Enterprise",
    image: Enterprise,
  },
  {
    id: "paytech",
    title: "Paytech",
    image: Paytech,
  },
  {
    id: "visa",
    title: "Visa Award",
    image: VisaAward,
  },
  {
    id: "setsquared",
    title: "SetSquared Award",
    image: SetSquared,
  },
  {
    id: "worldbank",
    title: "World Bank Award",
    image: Worldbank,
  },
];
const AwardsSection = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.awardsSection}>
      <div className="sectionContainer">
        <motion.h2
          {...revealContainerProps}
          variants={revealVariant}
          className={styles.awardsSection__title}
        >
          {t("home.awardsSection.title")}
        </motion.h2>
        <motion.div
          {...revealContainerWithChildrenProps}
          className={styles.awardsSection__awards}
        >
          {awards.map((award) => (
            <motion.img
              variants={revealVariant}
              key={award.id}
              src={award.image}
              alt="Award"
              data-award={award.id}
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
};

const Acquirers = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <HeroSection />
      <Partners />
      <ProductsSection />
      <ZealSandbox />
      <div id="schedule" className={styles.ScheduleSection}>
        <ScheduleSection
          title="home.calendly.title"
          subtitle="home.calendly.subtitle"
        />
      </div>

      <AwardsSection />
      <YellowSection
        title={t("acquirers.yellowSection.title")}
        description={t("acquirers.yellowSection.description")}
        url="https://sandbox.zealpay.app/api-docs"
        btnText={t("acquirers.yellowSection.button")}
      />
    </Layout>
  );
};

export default Acquirers;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "acquirers"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";
  return (
    <Seo isArabic={isArabic} title={isArabic ? "Acquirers" : "Acquirers"} />
  );
};
